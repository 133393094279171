import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Stockists" description="Archibald James ciders are available at these shops and restaurants." mdxType="SEO" />
    <article className="mx-auto prose prose-xl">
      <h1 {...{
        "id": "stockists"
      }}>{`Stockists`}</h1>
      <h2 {...{
        "id": "where-to-find-us"
      }}>{`Where to find us`}</h2>
      <p><strong parentName="p">{`Wines`}</strong>{`: available exclusively to `}<a parentName="p" {...{
          "href": "/club"
        }}>{`club members`}</a>{` and `}<a parentName="p" {...{
          "href": "/leavenworth"
        }}>{`tasting room`}</a>{` guests`}</p>
      <p><strong parentName="p">{`Ciders`}</strong>{`: experimental products are exclusively direct; rotating selection of dry, off-dry, and seasonal varieties are distributed to select shops and restaurants in Washington, Oregon & California`}</p>
      <h2 {...{
        "id": "distribution-points"
      }}>{`Distribution points:`}</h2>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`City`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Store`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Albany, CA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Oddlots Wine Shop`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Ashland, CA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Ashland Food Cooperative`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Ashland, CA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Gils`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Astoria, OR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Brut Wine Bar`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Bainbridge Island, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Town & Country Mrkt # 215`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Baker City, OR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Bella`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Bellevue, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #20 Bellevue`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Bellingham, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Hundred North`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Bellingham, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Jack'S`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Berkeley, CA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Dr. Mendelsohn`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Bothell, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #16 Bothell`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Bothell, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #17 Burien`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Cannon Beach, OR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Cannon Beach Fresh Foods`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Corvallis, OR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Corvallis Brewing Supply`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Eastsound, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Doe Bay Wine Co (Ste D1`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Edmonds, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #13 Edmonds`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Eugene, OR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Capella`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Hillsboro, OR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New Seasons - Orenco`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Issaquah, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #10 Issaquah`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Kirkland, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #2 Kirkland`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Los Angeles, CA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Martin Liquor And Market`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Los Angeles, CA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Sara'S Market`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Marysville, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Wine Shop-Marysville`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Medford, OR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Harry & David`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Medford, OR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Margo'S Wine Shop`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Mill Creek, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Central Mrkt Mc # 217`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Mount Vernon, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Skagit Vly Food Co-Op`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Oakland, CA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Broadway Liquor`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Olympia, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Dockside Bistro`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Olympia, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Mercato`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Pacifica, CA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Grape In The Fog`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Port Townsend, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Getables`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Portland, OR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Blackbird Wine Shop`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Portland, OR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`John'S Marketplace`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Redmond, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #12 Redmond`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Santa Cruz, CA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Staff Of Life Bakery`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A1 Market- #144`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Central Co-Op Inc`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Chuck'S Hop Shop - Cd`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Ken'S Market @ Greenwood`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Nw Liquor And Wine`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc # 18 Ballard`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc # 24 Central District`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #11 Fremont`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #14 Greenlake Village`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #15 Columbia City`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #19 West Seattle`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #5 View Ridge`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Pcc #9 Aurora Greenlake`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Beer Junction`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Seattle, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Vino Verite`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Shoreline, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Shoreline Market #212`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Spokane, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Bottles`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Tacoma, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Stadium Thriftway`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Vashon Island`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Harbor Mercantile`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`McGlinn’s Public House`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Plaza Super Jet`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee Valley Brewing Co.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Badger Mountain Brewing`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Riverfront Rock Gym`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Sweetwood BBQ`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tap and Putt`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Stone’s Gastropub`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Sidecar Lounge`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee Natural Foods`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Wally’s House of Booze`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Lone Pine Espresso`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Bob’s Burgers and Brews`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wenatchee, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Western Market`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Leavenworth, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Leavenworth Cider House`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Leavenworth, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Bushel & Bee Taproom`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Leavenworth, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Stein`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Leavenworth, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Rhein Haus`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Leavenworth, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Loft`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Leavenworth, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Dan’s Market`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Leavenworth, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Sage Mountain Natural Foods`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Leavenworth, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Smallwoods`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Leavenworth, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`97 Rock House`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Cashmere, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Martin’s Market Place`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Cashmere, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Milepost 111`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Cashmere, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Anjou Bakery`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Methow Valley, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Glover Street Market`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Methow Valley, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Mazama Store`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Ellensburg, WA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Happy’s Market`}</td>
          </tr>
        </tbody>
      </table>
      <p>{`Can't find us near you? Join our `}<a parentName="p" {...{
          "href": "/club"
        }}>{`club`}</a>{` and have it delivered.`}</p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      